
import beam_hoistway from '../../assets/img/company/beam_hoistway.jpg';
import high_rise from '../../assets/img/company/hoistway_vm_towers.jpg';
import escalator_installation from '../../assets/img/company/escalator_installation.jpg';
import modern_escalator from '../../assets/img/company/modern-escalator.jpg';
import glass_lift from '../../assets/img/company/glass_lift.jpg';
import panoramic from '../../assets/img/company/panoramic-elevator.jpg';
import wood_stainless from '../../assets/img/company/wood_stainless.jpg';
import laminated_finish from '../../assets/img/company/fifth.jpg'
import gold_interior from '../../assets/img/company/gold_interior.jpg';

export const ProjectPhotos = [
  {
      id:1, // Used in JSX as a key
      name:"Separating Beams",
      category:"Hoistway",
      image:beam_hoistway,
      desc: "It's essential to follow the latest versions of relevant codes, such as the International Building Code (IBC) and ASME A17.1, which outline safety standards for elevators and hoistways."
  },
  {
      id:2,
      name:"High Rise",
      category:"Hoistway",
      image:high_rise,
      desc: "Elevator hoistways must adhere to specific fire resistance ratings, especially in high-rise buildings or those lacking fire suppression systems. This includes implementing hoistway opening protection to prevent smoke migration and ensure the safety of first responders."
  },
  {
      id:3,
      name:"Escalator Installation",
      category:"Escalator",
      image:escalator_installation,
      desc: "Our service caters for escalators in commercial and domestic buildings in open and enclosed areas."
  },
  {
      id:4,
      name:"Modern Escalator",
      category:"Escalator",
      image:modern_escalator,
      desc: "Modern escalators have safety features like sensors that monitor step height and alignment, which trigger an alarm if misalignment occurs. Despite these features, escalators can still pose risks, particularly for vulnerable users such as children and the elderly. Owners should promote awareness of safety signs and instructions to help minimize accidents."
  },
  {
      id:5,
      name:"Glass Elevator",
      category:"Glass",
      image:glass_lift,
      desc: "Purchasing a glass elevator not only elevates the aesthetic of your home but also enhances functionality and accessibility, making it a worthwhile investment."
  },
  {
      id:6,
      name:"Panoramic",
      category:"Glass",
      image:panoramic,
      desc: "We excel at creating bespoke glass elevators, intelligently constructed and beautifully designed with custom-made functionality for every space."
  },
  {
      id:7,
      name:"Wood & Stainless Steel",
      category:"Custom Finish",
      image:wood_stainless,
      desc: "Combining wood and stainless steel in elevator interiors creates a striking visual contrast. The warmth of wood complements the sleek, modern look of stainless steel, allowing for a sophisticated and inviting atmosphere that can enhance the overall design of a building."
  },
  {
      id:8,
      name:"Laminated",
      category:"Custom Finish",
      image:laminated_finish,
      desc: "Laminated elevator interiors are cost-effective, visually appealing, and durable. They mimic expensive materials, resist scratches and moisture, and offer various colors and patterns for customization, making them a versatile choice for any building."
  },
  {
    id:9,
    name:"Gold",
    category:"Custom Finish",
    image:gold_interior,
    desc: "Gold elevator interior exudes luxury and sophistication, making it an eye-catching feature in any building. The rich, warm tones of gold can elevate the overall design and ambiance, creating a welcoming and opulent atmosphere.."
},
];
