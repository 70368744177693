import React from 'react';
import { questions } from './Data'
import Question from './Question';
import './Faq.css';
import { MdOutlineLibraryBooks } from 'react-icons/md';

const Faq = () => {
  
  return (
    <div className="container faq" id="faq" data-aos="fade-up">
      <div className="u-title">
        <MdOutlineLibraryBooks color="orangered" size={30} />
        <h2>FAQs</h2>
          {
        <p className="u-text-small">
          We have the answers to your most pressing lift solution questions
        </p>
        }
      </div>
      <div className="question">
        {questions.map((question) => (
          <Question
            key={question.id}
            title={question.title}
            answer={question.answer}
          />
        ))}
      </div>
    </div>
  );
};

export default Faq;
