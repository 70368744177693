import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { AiOutlineBars } from "react-icons/ai";
import { FaHome } from "react-icons/fa";
import { RiCloseLine } from "react-icons/ri";

import Logo from "../../assets/img/company/logo.png";
import Button from "../UI/Button/Button";
import "../UI/Button/Button.css";
import "./Navbar.css";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="navbg">
      <nav className="navbar">
       <div className="logo">
          <NavLink to="/">
            <img src={Logo} alt="logo" />
          </NavLink>
          <p className="logo-text">
            DEXTERITY LIFTS <span>&</span> ELECTRICAL LIMITED
          </p>
        </div>
        <menu>
          <ul
            className="nav-links fw-bold"
            id={showMenu ? "nav-links-mobile" : "nav-links-mobile-hide"}
          >
            <li className="active">
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="/"
              >
                <FaHome/> Home
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="/services"
              >
                Services
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="/contact"
              >
                Contact
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "")}
                to="/about"
              >
                About
              </NavLink>
            </li>
            <li className="nav-btn">
              <Button
                text={"Learn More"}
                btnclassName={"btn-dark"}
                href={"/#faq"}
              />
            </li>
          </ul>
        </menu>
        <div className="menu-icons" onClick={toggleMenu}>
          {showMenu ? (
            <RiCloseLine color="#fff" size={20} />
          ) : (
            <AiOutlineBars color="#fff" size={20} />
          )}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
