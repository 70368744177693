import React from "react";
import { FaAccessibleIcon, FaWrench } from "react-icons/fa";
import { SiDatabricks } from "react-icons/si";
import { SiCrystal } from "react-icons/si";
import { MdConnectWithoutContact } from "react-icons/md";

export const FeatureList = [
  {
    id: 1,
    icon: <FaAccessibleIcon color="#fff" size={14} />,
    heading: "Designed for you",
    text: "We provide lift solutions for every passenger in every building to keep them moving. Engineered for any project, both new construction and refurbished buildings.",
  },
  {
    id: 2,
    icon: <FaWrench color="#fff" size={14} />,
    heading: "Take control",
    text: "Maximize energy efficiency by combining LED lighting, standby-mode and regenerative technology as standard.",
  },
  {
    id: 3,
    icon: <MdConnectWithoutContact color="#fff" size={14} />,
    heading: "Remote Consultation",
    text: "Cost-Effectiveness: One of the primary advantages of remote consulting is the reduction in travel expenses. Flexibility and Accessibility: Remote consulting offers greater flexibility for both us and clients. Increased Availability: Remote consulting allows Dexterity Lifts to be more accessible to clients.",
  },
  {
    id: 4,
    icon: <SiDatabricks color="#fff" size={14} />,
    heading: "Keep Your History",
    text: "Unlock the full potential of your elevators with our bespoke preventive maintenance solutions. We will keep tract from project inception to its EOF ensures that every phase of your elevator's life cycle is meticulously documented and optimized. Our tailored approach not only enhances operational efficiency but also extends the lifespan of your systems, reducing downtime and costs. Invest in a future where your elevators operate seamlessly, backed by a rich history of care and attention to detail. Make the smart choice for your building—experience the difference with our expert maintenance services today!",
  },
  {
    id: 5,
    icon: <SiCrystal color="#fff" size={14} />,
    heading: "Caring is our business",
    text: "Caring is our one of our competitive advantage. Everyone here really cares not only about the company's affairs but also each customer needs. We are committed in prioritizing every customer needs and experiences. At Dexterity Lifts  we encourage employees to make decisions that benefit the customer without bureaucratic delays.",
  },
];
