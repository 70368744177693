import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Button from "../UI/Button/Button";
import "./ImageBg.css";

class ImageBg extends Component {
  render() {
    return (
      <div className="imgagebg">
        <div className="hero-img">
          <div className="heading">
            <h1  data-aos="fade-down">
              <span>{this.props.heading}</span>
            </h1>
            <p className="desc"  data-aos="fade-up">
              <span>{this.props.text}</span>
            </p>
            <h4 className="h4-text-small"  data-aos="fade-up">
            We are a proud distributor of a global elevator and escalator brand known for innovative, safe and efficient solutions. Our commitment to quality and customer satisfaction ensures comprehensive support for installation, repairs and maintenance, solidifying our position as a trusted industry leader.            
            </h4> 
            <div className='button'>
              <NavLink to="/contact">
                <Button text={"Contact US"} className={"btn btn-dark"} />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ImageBg;
