import React, { useEffect, useState } from "react";
import { ProjectPhotos } from "./ProjectPhotos";
import Preview from "./Preview";
import "./Project.css";

const Project = () => {
  // State variables for managing filters, photos, modal, and slide number
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filteredPhotos, setFilteredPhotos] = useState([]);

  // Available filters for the gallery
  const filters = ["All", "Hoistway", "Escalator", "Glass", "Custom Finish"];

  // Function to handle filter button clicks
  const handleFilterButtonClick = (selectedCategory) => {
    // If "All" is clicked, reset filters
    if (selectedCategory === "All") {
      setSelectedFilters([]); // Clear all selected filters
    } else {
      setSelectedFilters(
        (prev) =>
          prev.includes(selectedCategory)
            ? prev.filter((el) => el !== selectedCategory) // Remove category if already selected
            : [...prev, selectedCategory] // Add category if not selected
      );
    }
  };

  // Effect to filter photos based on selected filters
  useEffect(() => {
    const filterItems = () => {
      if (selectedFilters.length > 0) {
        // Filter photos based on selected categories
        const tempItems = ProjectPhotos.filter((item) =>
          selectedFilters.includes(item.category)
        );
        setFilteredPhotos(tempItems);
      } else {
        // If no filters are selected, show all photos
        setFilteredPhotos(ProjectPhotos);
      }
    };

    filterItems();
  }, [selectedFilters]);

  return (
    <div className="image-view">
      <div className="galleryWrapper">
        <div className="filterItem">
          <div className="u-title" data-aos="fade-up">
            <h2 className="u-title h2">Gallery filter with buttons</h2>
            <h3 className="u-text-dark">Click on image to view</h3>
          </div>
          <ul data-aos="zoom-in" data-aos-delay="200">
            {filters.map((category, idx) => (
              <li key={`filters-${idx}`}>
                <button
                  className={`button ${
                    selectedFilters.includes(category) ? "active" : "disabled"
                  }`}
                  onClick={() => handleFilterButtonClick(category)}
                >
                  <span className="text">{category}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
        {/* Call images Preview here */ }
        <Preview filteredPhotos={filteredPhotos} />
      </div>
    </div>
  );
};

export default Project;
