import React from 'react'
import Header from '../components/header/Header'
import Navbar from '../components/nav/Navbar'
import FormContact from '../components/form/FormContact'
import Footer from '../components/footer/Footer'

const Contact = () => {
    return (
      <div className='main'>
        <Header />
        <Navbar />
        <FormContact />
        <Footer/>
      </div>
    )
}

export default Contact

