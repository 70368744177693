import React from "react";
import { FaWrench } from "react-icons/fa";
import { BsCalendarCheck } from "react-icons/bs";
import { SiDatabricks } from "react-icons/si";
import { GrHostMaintenance } from "react-icons/gr";
import { TbFileReport } from "react-icons/tb";
import { MdConnectWithoutContact } from "react-icons/md";
import { GiAutoRepair, GiModernCity, GiTalk } from "react-icons/gi";


export const ServiceData = [
  {
    id: 1,
    icon: <BsCalendarCheck color="#fff" size={40} />,
    heading: "Modernization",
    information: "We have an amazing track record of managing small modernization projects for customers with limited funds and delivering satisfaction.",
  },
  {
    id: 2,
    icon: <SiDatabricks color="#fff" size={40} />,
    heading: "Preventive Maintenance",
    information: "We provide lift solutions for every passenger in every building to keep them moving. Engineered for any project, both new construction and refurbished buildings.",
  },
  {
    id: 3,
    icon: <GrHostMaintenance color="#fff" size={40} />,
    heading: "Predictive Maintenance",
    information: "Our goal is to tailor maintenance contracts that fit your budget and needs.",
  },
  {
    id: 4,
    icon: <FaWrench  color="#fff" size={40} />,
    heading: "Repair",
    information: "Our policy for reducing downtime after lift breakdowns is to provide a quick, no-obligation survey and quote to reinstall your lift as soon as possible... and it's completely free!",
  },
  {
    id: 5,
    icon: <GiModernCity  color="#fff" size={40} />,
    heading: "Installation",
    information: "We provide lift solutions for every passenger in every building to keep them moving. Engineered for any project, both new construction and refurbished buildings.",
  },
  {
    id: 6,
    icon: <GiAutoRepair color="#fff" size={40} />,
    heading: "Lift Breakdown Callout",
    information: "From the moment a lift breakdown is reported to us we are ready to spring into action! All relevant details are entered into our database and given a priority in accordance with customer feedback.",
  },
  {
    id: 7,
    icon: <GiTalk color="#fff" size={40} />,
    heading: "Consultation",
    information: "Here we enhanced your access and quality by enabling remote consultations and regular follow-ups.",
  },
  {
    id: 8,
    icon: <TbFileReport  color="#fff" size={40} />,
    heading: "E-Reporting",
    information: "We provide lift solutions for every passenger in every building to keep them moving. Engineered for any project, both new construction and refurbished buildings.",
  },
  {
    id: 9,
    icon: <MdConnectWithoutContact color="#fff" size={40} />,
    heading: "Networking",
    information: "We provide lift solutions for every passenger in every building to keep them moving. Engineered for any project, both new construction and refurbished buildings.",
  },
];