import React from "react";
import Button from "../UI/Button/Button";
import "./Error.css";

const Error = () => {
  return (
    <div className="error">
      <h1 className="code">404</h1>
      <div className="page">
        <h4>
          {" "}
          Ooops!!! The page you are looking for is not found. Go back to the
          home page.
        </h4>
        <p>
          Sorry about that. We've logged the error for review, in case it's our
          fault.
        </p>
      </div>
      {
        <div className="button">
          <Button
            text={"Back to home"}
            btnclassName={"btn btn-dark"}
            href={"/home"}
          />
        </div>
      }

      <img className="img-2" src="img/img-2.svg" alt="space" />
    </div>
  );
};

export default Error;
