import React, { useState, useEffect } from "react";
import "./ToTop.css";

const ToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [opacity, setOpacity] = useState(0);

  const toggleVisibility = () => {
    if (window.scrollY > 300) { // Changed from pageYOffset to scrollY
      setIsVisible(true);
      setOpacity(1); // Fully opaque when visible
    } else {
      setOpacity(0); // Fully transparent when not visible
      setTimeout(() => setIsVisible(false), 300); // Delay hiding the button
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div className="top">
      {isVisible && (
        <button
          className="scroll-to-top"
          onClick={scrollToTop}
          style={{ opacity, transition: "opacity 0.3s ease" }} // Smooth transition for opacity
        >
          ^
        </button>
      )}
    </div>
  );
};

export default ToTop;
