import React, { useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";



const Preview = ({ filteredPhotos  }) => {

  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  // Function to open the modal and set the current slide
  const handleOpenModal = (index) => {
    setSlideNumber(index);
    setOpenModal(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Function to navigate to the previous image in the modal
  const prevSlide = () => {
    setSlideNumber((prev) => (prev === 0 ? filteredPhotos.length - 1 : prev - 1));
  };

  // Function to navigate to the next image in the modal
  const nextSlide = () => {
    setSlideNumber((prev) => (prev + 1 === filteredPhotos.length ? 0 : prev + 1));
  };

  return (
    <div className='show-image'>
    {openModal && (
        <div className="sliderWrap">
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="btnClose"
            onClick={handleCloseModal}
          />
          <FontAwesomeIcon
            icon={faCircleChevronLeft}
            className="btnPrev"
            onClick={prevSlide}
          />
          <FontAwesomeIcon
            icon={faCircleChevronRight}
            className="btnNext"
            onClick={nextSlide}
          />
          <div className="fullScreenImage">
            <img
              key={filteredPhotos[slideNumber]?.id}
              src={filteredPhotos[slideNumber]?.image}
              alt={filteredPhotos[slideNumber]?.category}
            />
          </div>
        </div>
      )}

      <div className="frame">
          {filteredPhotos.map((photo, idx) => (
            <div
              key={`ProjectPhotos-${idx}`}
              className="gallery-item"
              onClick={() => handleOpenModal(idx)} // Pass index instead of photo.id
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <img className="view" src={photo.image} alt={photo.category} />
              <div className="content">
                <div className="wrap">
                  <h1>{photo.name}</h1>
                  <p>{photo.desc}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

    </div>
  )
}

export default Preview