import React, { useState } from "react";
import "./Question.css";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { BsQuestionCircleFill } from "react-icons/bs";

const Question = ({ title, answer }) => {
  const [showAnswer, setShowAnswer] = useState(false);

  const handleClick = () => {
    setShowAnswer(!showAnswer);
  };

  return (
    <div className="question-container">
      <div className="question-title">
        <h4><BsQuestionCircleFill className="title-icon"/>{ title }</h4>
        <button className="question-icons" onClick={ handleClick }>
          { showAnswer ? (
            <AiOutlineMinus color="red" />
          ) : (
            <AiOutlinePlus color="#1f93ff" />
          )}
        </button>
      </div>
      <div className="question-answer">
        {showAnswer && <p className="u-text-small">{ answer }</p>}
      </div>
    </div>
  );
};

export default Question;
