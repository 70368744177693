import React from "react";
import { TeamProfile } from "./TeamProfile";
import TeamCard from "./TeamCard";
import "./team.css";

const Team = () => {
  return (
    <div className="team">
      <div className="team-wrapper">
        <div className="team-heading" data-aos="fade-up">
          <h2 className="t-title h2">Vision Statement</h2>
          <p>
            Our vision is to enable and support the most vulnerable individuals,
            as well as those with fast-paced lifestyles, to travel from low-rise
            to high-rise with ease and comfort. We are striving to supply
            inclusive and accessible lifts that cater to diverse needs of all
            stakeholders, ensuring a relaxing and stress-free travel. Through
            cutting-edge and dexterity designs, we aim to redefine the
            experience of the elevator world, making it a source of convenience
            and luxury for everyone.
          </p>
          <h2 className="t-title h2">Mission Statement</h2>
          <p>
            At our core, we are driven by the mission to provide the most
            reliable and economical vertical solutions to move passengers and
            goods seamlessly in the safest manner possible with our elevators,
            escalators, and other lifts innovations. Coupled with world-class
            services and features for all of our customers within all buildings,
            setting new benchmark in the industry.
          </p>
          <h2 className="t-title h2"> About Us</h2>
          <p>
            With over 6 years of industry experience, Dexterity Lifts is your
            top choice for elevator projects and maintenance services, providing
            excellent cost-to-value relationships. We offer a comprehensive
            elevator package, managing each project from design to
            manufacturing, installation, and final handover. Our team
            specializes in custom installations of passenger lifts, escalators,
            dumbwaiters, and car lifts. We also excel in obtaining derogation
            approvals and certifications for lifts with reduced pit and
            headroom. Our installations span various sectors, including public,
            private, and residential. We are committed to enhancing elevator
            design with our new integrated technology, which seamlessly blends
            into different environments.
          </p>
          <h3 className="text-bold">
            Our team consists of the best experts in the industry
          </h3>
          <p>
            Our company is led by a team of young, vibrant partners who bring a
            wealth of industry experience and a passion for delivering
            exceptional customer service. They have an unsurpassed collective of
            knowledge, skill and experience within every field of the lift
            industry; including - breakdown, repairs, servicing, modernization
            and installation of multi high-rise and low-rise units. At the core,
            a team player is ready, willing, and able to support the team—no
            matter what lies ahead. From the moment you contact us, you are not
            just purchasing a lift; you are going on a transforming journey. Our
            engineers have worked for and with several of the world’s biggest
            lift companies on some of JA’s largest projects. This combined
            knowledge means we not only are highly proficient in all aspects of
            our work but understand the importance of all the other aspect that
            come together to provide total customer satisfaction.
          </p>
          <p>
            Our approach is warm, friendly and unique in the fact that all
            management and directors are trained engineers. Each team member is
            dedicated and loyal to the company and have a sense of pride and
            ownership in what they produce. Our commitment extends far beyond
            the sale; we are your installation and support partners. Our promise
            to you is that at every level you will be dealing directly with
            qualified individuals who will be able to answer to any questions
            you may have, without delay.
          </p>
        </div>
        {/*-- Team Heading --*/}{" "}
        <div className="team-heading">
          <h2 className="t-title h2">Meet Our Experienced Team</h2>
        </div>
        {/*-- End .team-heading --*/}
        {/*-- Team Content --*/}
        <div className="team-content">
          {/*-- Team Member --*/}
          {TeamProfile.map((team, idx) => (
            <TeamCard
              key={idx}
              image={team.image}
              heading={team.heading}
              text={team.text}
            />
          ))}
          {/*-- End .team-member --*/}
        </div>
        {/*-- End .team-content --*/}
      </div>
      {/*-- End .we-do-content --*/}
    </div>
  );
};

export default Team;
