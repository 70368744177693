import React, { useState } from "react";
import { BsQuote } from "react-icons/bs";
import { ReactComponent as ArrowLeft } from "../../assets/img/testimonial/chevron-left-solid.svg";
import { ReactComponent as ArrowRight } from "../../assets/img/testimonial/chevron-right-solid.svg";
import { ReactComponent as Circle } from "../../assets/img/testimonial/circle-solid.svg";
import { Clients } from "./TestimonialData";
import "./Testimonial.css";

const inactiveColour = "hsla(221, 0%, 88%, 0.81)";
const activeColour = "hsla(221, 0%, 49%, 0.98)";

const Testimonial = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const arrayLength = Clients.length;

  // Function to activate the previous slide
  const activatePreviousSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + arrayLength) % arrayLength);
  };

  // Function to activate the next slide
  const activateNextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % arrayLength);
  };

  return (
    <div className="carousel-container">
      <div className="card-heading">
        <h2>Testimonial</h2>
        <h3>Our valued clients</h3>
        <p>
          Learn how our products and services have transformed businesses and
          exceeded expectations.
        </p>
      </div>
      <div className="cards" data-aos="fade-up">
        {[-1, 0, 1].map((offset) => {
          const index = (activeIndex + offset + arrayLength) % arrayLength;
          const client = Clients[index];
          return (
            <div
              key={client.id}
              className={`card ${offset === 0 ? "active" : offset === -1 ? "leftCard" : "rightCard"}`}
              data-aos="zoom-in"
              data-aos-delay={`${(offset + 1) * 100}`}
            >
              <img className="avatars" src={client.imgSrc} alt="avatar" />
              <div className="text">
                <h3 className="name">{client.name}</h3>
                <i className="position">{client.position}</i>
                <p className="testimony">
                  <BsQuote color="red" /> {client.quote} <BsQuote color="red" />
                </p>
              </div>
              {offset === 0 && (
                <div className="arrow-container">
                  <ArrowLeft className="arrow arrow-left" fill={activeColour} onClick={activatePreviousSlide} />
                  <ArrowRight className="arrow arrow-right" fill={activeColour} onClick={activateNextSlide} />
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="tracker">
        {Clients.map((_, index) => (
          <div className="circle" key={index}>
            <Circle fill={index === activeIndex ? activeColour : inactiveColour} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonial;
