import React from "react";
import { NavLink } from "react-router-dom";
import { FaPhone, FaMailBulk, FaClock } from 'react-icons/fa';
import Button from "../UI/Button/Button";
import './Header.css';

const Header = () => {
  return (
    <header className='wrapper'>
      <div className="topbar">
        <div className='clock'>
            {/* Icon for clock with hover effect */}
            <span className='icon'><FaClock /></span> Monday - Friday, 8AM to 5PM
        </div>
        <div className='email'>
            {/* Icon for email with hover effect and mailto link */}
            <span className='icon' onClick={() => window.location.href = 'mailto:service@dexterityliftsei.com'}>
                <FaMailBulk />
            </span> service@dexterityliftsei.com
        </div>
        <div className='phone'>
            {/* Icon for phone with hover effect and tel link */}
            <span className='icon' onClick={() => window.location.href = 'tel:+18784374690'}>
                <FaPhone />
            </span> Call us now +1 (878) 437-4690
        </div>
        <div className='button'>
          <NavLink to="/contact">
            <Button text={"Make Inquiry"} className={"btn btn-dark"} />
          </NavLink>
        </div>
      </div>
    </header>
  );
};

export default Header;
