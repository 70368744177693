import Escalator from '../../assets/img/company/modern-escalator.jpg';
import Laminated from '../../assets/img/company/glass-custom-laminated.jpg';
import Hoistway from '../../assets/img/company/otis_shaft.jpg';
import Wood_Stainless from '../../assets/img/company/wood_stainless.jpg';
import Fifth from '../../assets/img/company/fifth.jpg';

export const SliderImage = [
  {
    id:1,
    title: "Escalator",
    description: "This is the escalator slider Image of our carousel",
    urls: Escalator,
  },
  {
    id:2,
    title: " Laminated Finish",
    description: "This is the laminated finish slider Image of our carousel",
    urls: Laminated,
  },
  {
    id:3,
    title: "Hoistway",
    description: "This is the hoistway slider Image of our carousel",
    urls: Hoistway,
  },
  {
    id:4,
    title: "WoodS Stainless",
    description: "This is wood and stainlees steel slider Image of our carousel",
    urls: Wood_Stainless,
  },
  {
    id:5,
    title: "Fifth Slide",
    description: "This is the Fifth slider Image of our carousel",
    urls: Fifth,
  },
  {
    id:6,
    title: "Sixth Slide",
    description: "This is the Sixth slider Image of our carousel",
    urls: Hoistway,
  },
];
