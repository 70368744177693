import team_member from "../../assets/img/team/employee.jpeg";
import "./team.css";

export const TeamProfile = [
  {
    id: 1,
    image:team_member,
    heading: "Racki Bernard",
    text: "Elevator/Escalator Consultant",
  },
  {
    id: 2,
    image:team_member,
    heading: "Barrington Getten",
    text: "Operation Manager",
  },
  {
    id: 3,
    image:team_member,
    heading: "Dwayne Perry",
    text: "Project Manager",
  },
  {
    id: 4,
    image:team_member,
    heading: "Eugent Grant",
    text: "Senior Technician/Electrician",
  },
];


