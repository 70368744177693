import React from "react";
import { BsFacebook } from "react-icons/bs";
import { GrInstagram } from "react-icons/gr";
import { IoLogoTwitter } from "react-icons/io";
import "./team.css";

const TeamCard = ({ image, heading, text }) => {
  return (
    <div className="team-member" >
      <div className="image-wrapper" data-aos="fade-up" data-aos-delay="100">
        <img src={image} alt={heading}/>
      </div>
      <div className="team-desc">
        <h3 className="team-title">{heading}</h3>
        <p>{text}</p>
      </div>
      <ul className="social">
        <li>
          <a href={"#faq"} className="social-media">
            <BsFacebook />
          </a>
        </li>
        <li>
          <a href={"#faq"} className="social-media">
            <IoLogoTwitter />
          </a>
        </li>
        <li>
          {" "}
          <a href={"#faq"} className="social-media">
            <GrInstagram />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default TeamCard;
