import React from 'react'
import Header from '../components/header/Header'
import Navbar from '../components/nav/Navbar'
import Error from '../components/error/Error'
import Footer from '../components/footer/Footer'


const NotFound = () => {
    return (
      <div>
        <Header />
        <Navbar />
        <Error />
        <Footer/>
      </div>
    )
}

export default NotFound