import React, { useEffect, useState } from 'react';

// Styling
import './StatsCount.css';

const StatsCount = (props) => {
  // Destructure props for easier access
  const { label, number, duration, icon } = props.data;

  // State to hold the current count
  const [count, setCount] = useState("0");

  useEffect(() => {
    // Reset count to 0 when the component is mounted
    setCount("0");

    let start = 0;
    const end = parseInt(number.substring(0, 3)); // Get the first three digits of the number

    // If the start equals the end, exit early
    if (start === end) return;

    // Calculate the duration for each increment
    const totalMilSecDur = parseInt(duration);
    const incrementTime = (totalMilSecDur / end) * 1000;

    // Timer to increment the count
    const timer = setInterval(() => {
      start += 1;
      setCount(String(start) + number.substring(3)); // Update count with the remaining digits
      if (start === end) clearInterval(timer); // Clear timer when done
    }, incrementTime);

    // Cleanup timer on component unmount
    return () => clearInterval(timer);
  }, [number, duration]); // Dependencies to reset the counter when these change

  return (
    <div className="counter-column">
      <div className='counter-icon'>{icon}</div>
      <div className="counter-number">{count}</div>
      <div className="counter-label">{label}</div>
    </div>
  );
}

export default StatsCount;
