import first from '../../assets/img/testimonial/photo3.jpg'
import second from '../../assets/img/testimonial/photo8.webp'
import third from '../../assets/img/testimonial/user7-128x128.jpg'
import forth from '../../assets/img/testimonial/photo5.jpg'
import fifth from '../../assets/img/testimonial/user8-128x128.jpg'
 

export const Clients = [
    {
        id: 1,
        status: "inactive",
        imgSrc:  first,
        name: "Jane",
        position: "Property Manager",
        quote: "The Team at Dexterity Lifts are very co-operative from last minute changes to even spontaneous team meeting after working hours."
    },
    {
        id: 2,
        status: "inactive",
        imgSrc: second,
        name: "Davu",
        position: "CIO", 
        quote: "These guys know their stuff from planning to completion they included me from the start to end!"
    },
    {
        id: 3,
        status: "inactive",
        imgSrc:  third,
        name: "Grace",
        position: "Facility Manager",
        quote: "Knowledgeable and technical team that keeps me updated until the issue is resolved."
    },
    {
        id: 4,
        status: "active",
        imgSrc: forth,
        name: "Prince",
        position: "Finance Adviser",
        quote: "Hats up to their excellent maintenance and installation service."
    },
    {
        id: 5,
        status: "inactive",
        imgSrc: fifth,
        name: "Ronald",
        position: "Consultant",
        quote: "They have been working with our clients locally and regionally, with exceptional efficiency."
    }
];
