import React from 'react'
import Header from '../components/header/Header'
import Navbar from '../components/nav/Navbar'
import Project from '../components/project/Project'
import Product from '../components/products/Product'

import Footer from '../components/footer/Footer'


const Service = () => {
    return (
        <div>
            <Header />
            <Navbar />
            <Project />
            <Product />
            <Footer/>
        </div>
    )
}

export default Service