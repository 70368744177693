import React from "react";
import "./ServiceList.css";

const ServiceList = ({ icon, heading, information }) => {
  return (
    <div className="service">
      <span className="product-icon">{icon}</span>
      <h3 className="heading">{heading}</h3>
      <p className="service-text">{information}</p>
    </div>
  );
};

export default ServiceList;
