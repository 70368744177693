export const questions = [
  {
    id: 1,
    title: "Can I get a 1 stop elevator for my installation?",
    answer:
      "While it is common to hear someone say they only need a 1 stop elevator, there will always be a minimum of 2 stops when it comes to an elevator.",
  },
  {
    id: 2,
    title: "What factors influence elevator design in high-rise buildings?",
    answer:
      "Key factors include the number of floors, expected passenger traffic, and building layout. For instance, in a 25-story building with an average of 40 people per floor, careful planning is needed to optimize the number of elevators and their routes to minimize wait times during peak hours.",
  },
  {
    id: 3,
    title: "What are some common elevator safety features?",
    answer:
      "Safety features include emergency brakes, door sensors, and communication systems for emergencies. These features ensure passenger safety in case of malfunctions.",
  },
  {
    id: 4,
    title:
      "How often do I need to have inspection and maintenance for my elevator(s)?",
    answer:
      "Schedule maintenance is recommended monthly for the up keep of your lift(s). A complete inspection is often necessary when a lift is installed. After the initial inspection, a biannual or annual inspection is essential to your elevator operation.",
  },
  {
    id: 5,
    title: "Do you accommodate options for custom fixtures for my elevator(s)?",
    answer:
      "Yes, our flexibility allows for customization in design, materials, and finishes to match the aesthetic and functional requirements of different projects. If you are considering custom fixtures for your elevator(s), it would be best to contact us directly to discuss your specific requirements and explore the available options. We can provide detailed information on what can be customized and how to proceed with your request.",
  },
  {
    id: 6,
    title: "What is the highest in travel your elevator can go?",
    answer:
      "For high-rise buildings, our elevators can be designed to travel much higher, with some of our modern systems capable of reaching over 300 meters (approximately 1,000 feet) or more.",
  },
  {
    id: 7,
    title: "Do you offer free quotations?",
    answer:
      "Yes, we provide free, no obligation quotations as part of our service.  In order to provide you with an accurate quotation, our lift consultants will review your plans or are available to visit your property at a time that suits you. Email service@dexterityliftsei.com or send us a request via our contact form.",
  },
  {
    id: 8,
    title: "What happens if there is a power failure for my home elevator?",
    answer:
      "Yes, we offer free, no-obligation quotations as part of our service. To ensure an accurate quote, our lift consultants will review your plans or can visit your property at your convenience. Please email us at service@dexterityliftsei.com or submit a request through our contact form.",
  },
  {
    id: 9,
    title: "Can I view your elevators?",
    answer:
      "Yes, we have catologues and operational elevators at convenient locations that you can get access to view.",
  },
  {
    id: 10,
    title: "Where is the location of your company?",
    answer:
      "Our company is proudly based in the beautiful city of Montego Bay, St. James, and we extend our services across the entire island. If you would like to visit us, you are much welcomed!",
  },
];
