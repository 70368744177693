import React from "react";
import { MdOutlineHomeRepairService } from "react-icons/md";
import { ImHappy } from "react-icons/im";
import { RiTeamLine } from "react-icons/ri";
import { GoCommentDiscussion } from "react-icons/go";

export const CountData = [
    {
        id: "001",
        icon: <RiTeamLine />,
        label: "People Moved Daily",
        number: "100000",
        duration: "2",
    },
    {
        id: "002",
        icon: <MdOutlineHomeRepairService />,
        label: "Units Serviced",
        number: "150+",
        duration: "2",
    },
    {
        id: "003",
        icon: <ImHappy />,
        label: "Happy Clients",
        number: "300+",
        duration: "2"
    },
    {
        id: "004",
        icon: <GoCommentDiscussion />,
        label: "Complaints",
        number: "0",
        duration: "2"
    }
];





