import { useState, useEffect } from "react";

// hook to validate all forms validation function,
// and method to run when there are no errors
const UseForm = (validate, callback) => {

  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  // store list of errors
  const [errors, setErrors] = useState({});
  // touched is an array of all fields that have 
  // been touched by the user
  const [touched, setTouched] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);

  // use effect runs on initial component load and when errors object changes

  useEffect(() => {
    if (!isSubmitting) return; // Early return if not submitting
  
    const noErrors = Object.keys(errors).length === 0;
  
    if (noErrors) {
      setTouched([]); // Clear out touched upon submission
      callback(); // Run form submission
    }
  
    setSubmitting(false); // Reset submitting state
  }, [errors, isSubmitting, callback]);

  // Validates input to see if there are any errors
useEffect(() => {
  const validationErrors = validate(values);
  
  const touchedErrors = Object.keys(validationErrors).reduce((logger, key) => {
    if (touched.includes(key)) {
      logger[key] = validationErrors[key]; // Only include errors for touched fields
    }
    return logger;
  }, {});

  setErrors(touchedErrors);
}, [touched, values, validate]); // Removed eslint-disable comment

  // handle change event runs every time an input changes
  const handleChange = (event) => {
    
    switch (event.target.name) { // Check if the name exists in name
      case "name":
        setValues({
          ...values, // Copy the old fields
          name: event.target.value, // But override this one
        });
        break;
      case "email":
        setValues({
          ...values, // Copy the old fields
          email: event.target.value, // But override this one
        });
        break;
      case "message":
        setValues({
          ...values, // Copy the old fields
          message: event.target.value, // But override this one
        });
        break;
      default:
        break;
    }
  };

  // handle mui-tel-input from user
  const handlePhone = (newValue) => {
    setValues({
      ...values, // Keep the old fields
      phone: newValue, // But override this one
    });
  };

  // handle blur is run when a form is tapped or
  // tabbed into/ 'touched'
  const handleBlur = (event) => {

    // if touched hasn't been touched before add it to the array
    if (!touched.includes(event.target.name)) {
      setTouched([...touched, event.target.name]);
    }
  };

  // function for form submission
  const handleSubmit = (event) => {

    // Prevent default event to submit form
    event.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    setSubmitting(true);
  };

  // return functions, and values from hook to be used in the contact form component
  return {
    handleSubmit,
    handleChange,
    handleBlur,
    handlePhone,
    values,
    errors,
    isSubmitting,
  };
};

export default UseForm;
