import React from 'react'
import Header from '../components/header/Header'
import Navbar from '../components/nav/Navbar'
import Team from '../components/team/Team'
import Testimonial from '../components/testimonial/Testimonial'
import Slider from '../components/carousel/Slider'
import Footer from '../components/footer/Footer'


const About = () => {
    return (
      <div>
        <Header />
        <Navbar />
        <Slider />
        <Team />
        <Testimonial />
        <Footer/>
      </div>
    )
}

export default About