import React from "react";
// Importing icons for compliance section
import Mission from "../../assets/img/compliance/mission.png";
import Vision from "../../assets/img/compliance/vision.png"
import Values from "../../assets/img/compliance/values.png";
import "./Compliance.css";

const Compliance = () => {
  return (
    <div className="ind-common-pad2 clearfix">
      {/* -- ======= Control in compliance ======= -- */}
      <div className="quality-wrapper text-center">
        <div className="vision tab-panel fade in">
          <h1 className=".fancy-heading">
            Experience top-tier Quality Control that meets both National and
            International regulations and standards
          </h1>
          <p>
            In today's competitive landscape, ensuring the highest quality in
            our products and services is not just an option—it's a necessity.
            Experience top-tier Quality Control with our dedicated team, who are
            committed to meet both National and International regulations and
            standards. We believe that quality is the foundation of success, and
            our meticulous approach guarantees that our units will shine in
            your building, giving it the image to stand out. With our
            comprehensive Quality Control processes, every stage of our
            installation or maintenance is meticulously monitored and refined. We prioritize
            excellence at every turn, ensuring that our customers receive
            nothing less than the best. By partnering with us, you're not just
            investing in quality—you're investing in a reputation that reflects
            reliability and trust. Let us help you elevate and
            achieve unparalleled success in your building!
          </p>
        </div>
        <div className="vision-wrapper text-center">
          <ul>
          <li>
              <a id="vision" href={"/#faq"}>
                <img src={Vision} alt="vision icon" />
                <p>Vision</p>
              </a>
            </li>
            <li>
              <a id="mission" href={"/#faq"}>
                <img src={Mission} alt="mission icon" />
                <p>Mission</p>
              </a>
            </li>
            <li>
              <a id="values" href={"/#faq"}>
                <img src={Values} alt="Values Icon" />
                <p>Values</p>
              </a>
            </li>
           
          </ul>
        </div>
      </div>
      {/* -- ======= End of Control in compliance ======= -- */}
    </div>
  );
};

export default Compliance;
