
import { matchIsValidTel } from "mui-tel-input";

function FormValidation(value) {
    
    // Declaration of null variable for errors
    const errors = {}
    // Declaration of null variable for errors
    const email_pattern =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

   
    // Validate fullName here
    if (!value.name.trim()) {
        errors.name = '❌  Your full name is required';
    }else if (value.name.length < 5){
        errors.name = '❌  Your full name should have at least have 5 character'
    } else if (value.name.length > 50){
        errors.name = '❌  Your full name should have not be more than 50'
    }

    // Validate email here
    if (!value.email.trim()){
        errors.email = '❌  Email is required';
    }
    else if (!email_pattern.test(value.email)) {
        errors.email = '❌  Email address is invalid - Format email@domain.com';
    }
      
    // Validate phone number here
    if (!matchIsValidTel(value.phone))  {
        errors.phone = ("❌ Please enter a valid phone number example: [+61 XXX-XXX-XXX]");
    }

    // Validate message here
    if (!value.message.trim()) {
        errors.message = '❌  Send us a message for us to get back to you';
    }else if (value.message.length < 10){
        errors.message = '❌  Your message should have at least 10 characters for clarity!';
    } else if (value.message.length > 500){
        errors.message = '❌  Your message should not be over 500 characters!';
    }

    // Errors return here
    return errors;
}
export default FormValidation

