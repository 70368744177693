import React from 'react'
import Navbar from '../components/nav/Navbar'
import ImageBg from '../components/imagebg/ImageBg'
import Features from '../components/features/Features'
import Process from '../components/process/Process'
import Faq from '../components/faq/Faq'
import Footer from '../components/footer/Footer'
import Header from '../components/header/Header'

const Home = () => {
  return (
    <div className='main'>
      <Header />
      <Navbar />
      <ImageBg heading='ELEVATOR-ESCALATOR ⚡ & MORE!' text='First-Class Quality in the world of elevators' />
     <Features />
      <Process /> 
      <Faq />
      <Footer />
    </div>
  )
}

export default Home