import React from 'react';

import { MdOutlineHealthAndSafety } from 'react-icons/md';
import { BsSpellcheck } from 'react-icons/bs';
import { AiOutlineDollarCircle } from 'react-icons/ai';
import { BiSupport } from 'react-icons/bi';
import { GrHostMaintenance } from 'react-icons/gr';
import { GiElevator } from 'react-icons/gi'

const iconStyle = (Icon) => <Icon size="3rem" color="#0f0f0f" />;

export const ProcessData = [
	{
		name: 'Best Safety',
		description: 'All our units are equipped with rescue power supplies. In the event of power failure, this feature enables you to exit the lift, without external assistance.',
		icon: iconStyle(MdOutlineHealthAndSafety),
		id: 1,
	},
	{
		name: 'Ease of Use',
		description: 'Our system is easy to use and integrate. They are designed to support individuals with physical challenges and ensure accessibility for everyone.',
		icon: iconStyle(BsSpellcheck),
		id: 2,
	},
	{
		name: 'Price',
		description: 'Fastest repair service at the best price!',
		icon: iconStyle(AiOutlineDollarCircle),
		id: 3,
	},
	{
		name: 'Maintenance',
		description: 'Our Maintenance Management System is a predictive and preventative system, designed to reduce the number of service calls – and downtime.',
		icon: iconStyle(GrHostMaintenance),
		id: 4,
	},
	{
		name: '24/7 Support',
		description: 'Our dedicated team is available in case you need us for prompt professional service.',
		icon: iconStyle(BiSupport),
		id: 5,
	},
	{
		name: 'Scalable',
		description: 'Dexterity Lifts roomless(MRL) models offer flexible solutions, with a low pit and overhead compared to conventional lifts.',
		icon: iconStyle(GiElevator),
		id: 6,
	},
];
