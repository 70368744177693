import React from "react";
import elevator_Installation from "../../assets/img/company/elevator-installation.png";
import ServiceList from "./ServiceList";
import { ServiceData } from "./ServiceData";
import "./Product.css";

const Product = () => {
  return (
    <div className="service-wrapper">
      <div className="our-service">
        <div className="u-title" data-aos="fade-up">
          <h2 className="u-title h2">Our Services</h2>
          <h3>
            Our experienced and dedicated staff provide these services with a
            smile.
          </h3>
          <p className="u-title p">
            We are the sole distributor for the world’s leading brand for elevator
            and escalator, installation and service. Regardless of the request,
            design, or need, we approach each project with the same dedication to
            provide exceptional client satisfaction, precise attention to detail,
            with superior quality and safety standards.
          </p>
        </div>
        
        <div className="circle-menu" data-aos="fade-up">
          <ul>
            <li><a className="modernization" href={"/#faq"}><span>Modernization</span></a></li>
            <li><a className="preventive" href={"/#faq"}><span>Preventive</span></a></li>
            <li><a className="predictive" href={"/#faq"}><span>Predictive</span></a></li>
            <li><a className="repair" href={"/#faq"}><span>Repairs</span></a></li>
            <li><a className="installation" href={"/#faq"}><span>Installation</span></a></li>
            <li><a className="breakdown" href={"/#faq"}><span>Breakdown</span></a></li>
            <li><a className="consultation" href={"/#faq"}><span>Consultation</span></a></li>
            <li><a className="report" href={"/#faq"}><span>E-Reporting</span></a></li>
            <li><a className="networking" href={"/#faq"}><span>Networking</span></a></li>
          </ul>
        </div>
      </div>

      <div className="services-content">
        <div className="services-left" data-aos="fade-up">
          <div className="service-row" data-aos="fade-out" data-aos-delay="200">
            {ServiceData.map((item) => (
              <ServiceList
                key={item.id}
                icon={item.icon}
                heading={item.heading}
                information={item.information}
              />
            ))}
          </div>
        </div>

        <div className="services-right" data-aos="fade-in">
          <img src={elevator_Installation} alt="gen2 comfort" />
        </div>
      </div>
    </div>
  );
};

export default Product;
