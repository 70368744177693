import React from "react";
import {
  FaRegComment,
  FaBuilding,
  FaWrench,
  FaNetworkWired,
} from "react-icons/fa"; // Ensure you import the icons
import "./FlexCard.css";

const flexCardData = [
  {
    id: 1,
    colorClass: "flexcardOrange",
    title: "Consultation",
    subtitle: "Talk To Us",
    text: "We will provide you with the knowledge and insights needed to make informed decisions about your elevators, boosting your confidence in their management and promoting a proactive approach to meeting international standards.",
    icon: <FaRegComment color="#fc5412" size={45} />,
    delay: 100,
  },
  {
    id: 2,
    colorClass: "flexcardGreen",
    title: "Installation",
    subtitle: "Leave The Job To Us",
    text: "Our objective is to ensure a seamless installation process, aimed at meeting clients' specific needs while adhering to safety standards and maximizing operational performance.",
    icon: <FaBuilding color="#fc5412" size={45} />,
    delay: 200,
  },
  {
    id: 3,
    colorClass: "flexcardBlue",
    title: "Repairs",
    subtitle: "We Will Fix It",
    text: "We supply OEM parts to promptly and effectively address issues, allowing us to restore your elevator(s), enhance their longevity, and maintain a high standard of performance.",
    icon: <FaWrench color="#fc5412" size={45} />,
    delay: 300,
  },
  {
    id: 4,
    colorClass: "flexcardCarmine",
    title: "Maintenance",
    subtitle: "Prioritize Upkeeping",
    text: "Preventive Maintenance Packages ensure safe and reliable elevator operation by minimizing breakdowns, maximizing uptime, and extending equipment lifespan.",
    icon: <FaNetworkWired color="#fc5412" size={45} />,
    delay: 400,
  },
];

export default flexCardData;
