import React from "react";
import Panoramic from "../../assets/img/company/glass_lift.jpg";
import { FeatureList } from "./FeaturesData";
import Compliance from "./Compliance";
import Feature from "./Feature";
import FlexCard from "./FlexCard";
import "./Features.css";

const Features = () => {
  return (
    <div className="features">
      <FlexCard />
      <Compliance />
      <div className="features-content">
      <div className="u-title" data-aos="fade-up">
        <h2 className="u-title h2">Why choose us?</h2>
        <p className="u-title p">
          BY COMBINING EXPERTS WITH EXPERIENCE, WE MAKE THE ELEVATOR WORLD
          AFFORDABLE AND RELIABLE FOR BUSINESS!
        </p>
        <p>
          Unlock the power of our expertise! We cater to a wide range of
          electrical needs for businesses, ensuring they have reliable and
          efficient electrical systems in place. Let us help you light the way
          to your elevator and electrical needs today with a consultation and
          experience the difference!
        </p>
      </div>
        <div className="features-left" data-aos="fade-up">
          <img src={Panoramic} alt="panoramic" />
        </div>
        <div className="features-right" data-aos="fade-down">
          {FeatureList.map((feature) => (
            <Feature
              key={feature.id}
              icon={feature.icon}
              heading={feature.heading}
              text={feature.text}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
